<template>
  <div class="contact content-wrapper">
    <div class="left">
      <!-- <h1>Nøjagtig interiør montage med øje for detaljer.</h1> -->
      <p>
        Hos Monto Danmark er vi drevet af en passion for godt håndværk og stærk faglighed. Vi tror på, at gennemsigtighed og god kommunikation giver den bedste proces og kvalitet. Og vi har et stort
        fokus på at holde samme kvalitet hele vejen igennem projekterne og under alle typer opgaver.
      </p>
      <p>
        Derfor har vi udviklet en platform, der sikrer god kommunikation gennem hele processen - fra værksted til væg. Platformen består af en udregner, som leder jer igennem opgaven, og giver os et
        overblik over alle processer i jeres projekt - uden tab af oplysninger. Men platformen giver også jer mulighed for at have et overblik over åbne og lukkede sager, der er i gang.
      </p>
      <p>
        Vi håber, I vil tage godt imod platformen. Skriv til mads@montocph.dk hvis I har spørgsmål, er interesserede i en prøveperiode på platformen eller en snak om et montage samarbejde.
      </p>
    </div>
    <div class="right">
      <h2>Kontakt</h2>
      <p>
        Monto Cph<br />
        Vasekær 4<br />
        2730 Herlev<br />
        Danmark<br /><br />
        Tel: +45 28685164<br />
        Email: mads@montocph.dk<br />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact',
}
</script>

<style scoped lang="scss">
.contact {
  display: grid;
  align-items: start;
  margin-bottom: 2rem;
}
.left {
  margin-bottom: 1rem;
}
.left > h1 {
  margin-top: 0;
  color: #4d4d4d;
  font-weight: 400;
}
.left > p {
  line-height: 25px;
  &:first-of-type {
    margin-top: 0;
  }
}
.right {
  line-height: 25px;
  padding: 1.5rem;
  width: 100%;
  background-color: #f6f5f5;
  box-sizing: border-box;
}
.right > h2 {
  margin-top: 0;
  color: #4d4d4d;
  font-size: 1.25rem;
}
.right > p {
  margin-bottom: 0;
}
@media screen and (min-width: 600px) {
  .contact {
    margin-top: 3rem;
    grid-template-columns: 50% 50%;
  }
  .right {
    padding: 5rem;
  }
  .left {
    margin: 0 4rem 0 0;
  }
  .left > h1 {
    margin-bottom: 2.5rem;
  }
}
</style>
